import React, { useContext } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import classnames from "classnames";
import PropTypes from "prop-types";
import { InputBase } from "@mui/material";
import { observer } from "mobx-react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { BoxWrapper } from "../../../shared-components";

function MenuSearch({ isTabletOrLarger }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { search } = mainStore.menu;

  // Functions
  function onChange(event) {
    mainStore.menu.setSearch(event.target.value);
  }

  return (
    <BoxWrapper
      className={classnames("tw-gap-x-2.5 tw-cursor-default", {
        "tw-w-72": isTabletOrLarger,
      })}
    >
      <SearchIcon />
      <InputBase
        placeholder="Пошук товарів..."
        fullWidth
        onChange={onChange}
        value={search}
        className="!tw-font-semibold"
      />
      {search && (
        <CloseIcon
          className="tw-cursor-pointer"
          onClick={() => mainStore.menu.setSearch("")}
        />
      )}
    </BoxWrapper>
  );
}

MenuSearch.propTypes = {
  isTabletOrLarger: PropTypes.bool,
};

export default observer(MenuSearch);
