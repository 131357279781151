import React, { useContext } from "react";
import { StoreContext } from "../../../../contexts/Store";
import { observer } from "mobx-react";
import { InfoPanel, MenuDivider } from "../../shared-components";
import { CONTAINER_MAX_WIDTH } from "../../constants";
import { Box, Container } from "@mui/material";
import classnames from "classnames";

function MenuFooter() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark, selectedProducts } = mainStore.menu;

  return (
    <Box
      className={classnames("tw-pb-5", {
        "tw-bg-neutral-50": !isThemeDark,
        "tw-bg-neutral-950": isThemeDark,
        "!tw-pb-16": !!selectedProducts.length,
      })}
      component="footer"
    >
      <MenuDivider className="!tw-mb-5" />
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <InfoPanel isFooter />
      </Container>
    </Box>
  );
}

export default observer(MenuFooter);
