import React, { useCallback, useContext, useEffect, useState } from "react";
import throttle from "lodash/throttle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MenuProducts } from "../menu-products";
import classnames from "classnames";
import { StoreContext } from "../../../../../contexts/Store";
import { observer } from "mobx-react";
import { Box, Container } from "@mui/material";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import MenuFooter from "../../menu-footer/MenuFooter";
import PropTypes from "prop-types";

const tabHeight = 116;

function useThrottledOnScroll(callback, delay) {
  useEffect(() => {
    const throttledCallback = callback ? throttle(callback, delay) : null;

    if (throttledCallback) {
      window.addEventListener("scroll", throttledCallback);
      return () => {
        window.removeEventListener("scroll", throttledCallback);
        throttledCallback.cancel();
      };
    }
  }, [callback, delay]);
}

function ScrollSpyTabs({ scrollableTabsData }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark, mainColor } = mainStore.menu;
  const tabElements = scrollableTabsData.map((tab) => {
    return {
      ...tab,
      node: document.getElementById(tab.id),
    };
  });

  // State
  const [activeTab, setActiveTab] = useState(null);
  const [isTabClicked, setIsTabClicked] = useState(false);
  const [timeoutTracking, setTimeoutTracking] = useState(null);

  // Hooks
  const findActiveIndex = useCallback(() => {
    if (isTabClicked) return;

    if (activeTab === null && tabElements.length > 0) {
      setActiveTab(tabElements[0]?.id);
      return;
    }

    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const threshold = clientHeight / 8 + tabHeight;

    const active = tabElements.reduceRight(
      (result, item) => {
        if (
          !result.id &&
          item.node &&
          item.node.offsetTop < scrollTop + threshold
        ) {
          return item;
        }
        return result;
      },
      { id: null },
    );

    if (active && active.id && activeTab !== active.id) {
      setActiveTab(active.id);
    }
  }, [activeTab, tabElements, isTabClicked, setActiveTab]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(tabElements.length > 0 ? findActiveIndex : null, 166);

  // Functions
  const handleClick = (id) => () => {
    clearTimeout(timeoutTracking);
    setIsTabClicked(true);
    const timeoutId = setTimeout(() => {
      setIsTabClicked(false);
    }, 1000);
    setTimeoutTracking(timeoutId);

    if (activeTab !== id) {
      setActiveTab(id);
    }

    window.scrollTo({
      top:
        document.getElementById(id).getBoundingClientRect().top +
        window.pageYOffset -
        tabHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Box
        className={classnames(
          "tw-py-2",
          "tw-sticky",
          "tw-top-[52px]",
          "tw-z-10",
          {
            "tw-bg-white": !isThemeDark,
            "tw-bg-neutral-925": isThemeDark,
          },
        )}
      >
        <Container maxWidth={CONTAINER_MAX_WIDTH}>
          <Tabs
            value={activeTab ? activeTab : tabElements[0].id}
            variant="scrollable"
            TabIndicatorProps={{
              hidden: true,
            }}
            className="tw-sticky tw-top-[52px] tw-z-10"
            scrollButtons={false}
          >
            {tabElements.map((tab) => {
              const isActive =
                activeTab === tab.id ||
                (tabElements[0].id === tab.id && !activeTab);

              return (
                <Tab
                  disableRipple
                  key={tab.id}
                  label={tab.name}
                  onClick={handleClick(tab.id)}
                  value={tab.id}
                  className="first:!tw-ml-0 !tw-ml-1 !tw-min-w-0 !tw-min-h-0 !tw-border !tw-border-neutral-400 !tw-border-solid !tw-rounded-lg !tw-p-2 !tw-font-semibold !tw-text-sm"
                  sx={{
                    color: isActive
                      ? `${mainColor} !important`
                      : "text.primary",
                    borderColor: isActive && `${mainColor} !important`,
                  }}
                />
              );
            })}
          </Tabs>
        </Container>
      </Box>

      {tabElements.map((tab) => (
        <MenuProducts
          key={tab.id}
          categoryID={tab.id}
          categoryName={tab.name}
          categoryDescription={tab.description}
        />
      ))}

      <MenuFooter />
    </>
  );
}

ScrollSpyTabs.propTypes = {
  scrollableTabsData: PropTypes.array.isRequired,
};

export default observer(ScrollSpyTabs);
