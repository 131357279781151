import React, { useContext } from "react";
import { StoreContext } from "../../../../../../contexts/Store";
import { observer } from "mobx-react";
import { Box, FormControlLabel, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import PropTypes from "prop-types";

function ProductPortions({ id, portions, selectedProduct }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { mainColor, selectedPortions } = mainStore.menu;
  const filteredPortions = portions?.filter((portion) => !!portion?.price);
  const filteredSelectedPortions = selectedPortions.filter(
    (portion) => portion.productId !== id,
  );
  const selectedPortion = selectedPortions.find(
    (portion) => portion.productId === id,
  );
  const portionPosition = selectedPortion ? selectedPortion.portionPosition : 0;
  const hasSelectedPortion = !!selectedProduct?.portions?.[portionPosition];

  // Functions
  function handleRadioChange(event) {
    const newPortionPosition = Number(event.target.value);

    const updatedPortions = filteredSelectedPortions.map((portion) =>
      portion.productId === id
        ? {
            ...portion,
            portionPosition: newPortionPosition,
          }
        : portion,
    );

    const finalPortions =
      newPortionPosition !== 0 &&
      !filteredSelectedPortions.some((portion) => portion.productId === id)
        ? [
            ...updatedPortions,
            {
              productId: id,
              portionPosition: newPortionPosition,
            },
          ]
        : updatedPortions;

    mainStore.menu.setSelectedPortions(finalPortions);
  }

  return (
    <>
      {filteredPortions?.length > 1 && (
        <>
          <Box className="tw-flex tw-items-center tw-flex-wrap tw-gap-x-2">
            {filteredPortions?.map((portion, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={index.toString()}
                  control={
                    <Radio
                      checked={index.toString() === portionPosition.toString()}
                      onChange={handleRadioChange}
                      sx={{
                        color: "text.primary",
                        padding: 0,
                        "&.Mui-checked": {
                          color: mainColor,
                        },
                      }}
                      disableRipple
                    />
                  }
                  onClick={(event) => event.stopPropagation()}
                  label={
                    <Box className="tw-flex tw-items-center tw-flex-wrap tw-gap-x-2">
                      {!!portion.portion_size && (
                        <Typography className="!tw-font-medium">
                          {portion.portion_size}:
                        </Typography>
                      )}
                      {!!portion.price && (
                        <Typography
                          className="!tw-text-lg !tw-font-bold tw-uppercase"
                          style={{ color: mainColor }}
                        >
                          {portion.price} {portion.units_for_price}{" "}
                          {!!selectedProduct?.portions[index]?.count && (
                            <>
                              X(
                              {selectedProduct.portions[index]?.count})
                            </>
                          )}
                        </Typography>
                      )}
                    </Box>
                  }
                  sx={{
                    margin: 0,
                    columnGap: "0.375rem",
                  }}
                />
              );
            })}
          </Box>
          <Typography
            className="!tw-text-lg !tw-font-bold tw-uppercase"
            style={{ color: mainColor }}
          >
            {hasSelectedPortion && (
              <>
                X{selectedProduct.portions[portionPosition]?.count}
                {filteredPortions[portionPosition]?.price &&
                  ` - ${
                    filteredPortions[portionPosition]?.price *
                    selectedProduct.portions[portionPosition]?.count
                  } ${
                    filteredPortions[portionPosition]?.units_for_price
                      ? filteredPortions[portionPosition]?.units_for_price
                      : ""
                  }`}
              </>
            )}
          </Typography>
        </>
      )}
      {filteredPortions?.length === 1 && (
        <Typography
          className="!tw-text-lg !tw-font-bold tw-uppercase"
          style={{ color: mainColor }}
        >
          {filteredPortions[portionPosition]?.price}{" "}
          {filteredPortions[portionPosition]?.units_for_price}
          {hasSelectedPortion && (
            <>
              {filteredPortions[portionPosition]?.price && " / "}X
              {selectedProduct.portions[portionPosition]?.count}
              {filteredPortions[portionPosition]?.price &&
                ` - ${
                  filteredPortions[portionPosition]?.price *
                  selectedProduct.portions[portionPosition]?.count
                } ${
                  filteredPortions[portionPosition]?.units_for_price
                    ? filteredPortions[portionPosition]?.units_for_price
                    : ""
                }`}
            </>
          )}
        </Typography>
      )}
    </>
  );
}

ProductPortions.propTypes = {
  id: PropTypes.string.isRequired,
  selectedProduct: PropTypes.object,
  portions: PropTypes.arrayOf(
    PropTypes.shape({
      portion_size: PropTypes.string,
      price: PropTypes.number,
      units_for_price: PropTypes.string,
      weight_or_volume: PropTypes.string,
      cook_time: PropTypes.string,
    }),
  ),
};

export default observer(ProductPortions);
