import React, { useContext } from "react";
import { MenuMainContent } from "./menu-main-content";
import { StoreContext } from "../../../contexts/Store";
import { observer } from "mobx-react";
import { MenuHeader } from "./menu-header";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, CircularProgress, circularProgressClasses } from "@mui/material";

function MenuViews() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { data, isThemeDark } = mainStore.menu;
  const theme = createTheme({
    palette: {
      mode: isThemeDark ? "dark" : "light",
    },
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overscrollBehavior: "none",
          },
          "*": {
            WebkitTapHighlightColor: "transparent",
            tapHighlightColor: "rgba(0, 0, 0, 0)",
          },
        },
      },
    },
  });

  if (!data) {
    return (
      <Box className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-2/4 tw--translate-y-2/4">
        <CircularProgress
          variant="determinate"
          className="!tw-text-neutral-300"
          size={40}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className="!tw-text-neutral-950 tw-absolute tw-left-0"
          sx={{
            animationDuration: "550ms",
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
        />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MenuHeader />
      <MenuMainContent />
    </ThemeProvider>
  );
}

export default observer(MenuViews);
