import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Sections {
  // POST
  async create(value, establishmentID) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        section_name: value,
        section_active: true,
      });

      message.success("Розділ створено");
    } catch (error) {
      message.error("Помилка при створенні розділу");
    }
  }

  // PUT
  async update(establishmentID, id, fieldParams) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await update(updateListRef, fieldParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // DELETE
  async delete(establishmentID, id) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await remove(deleteListRef);

      message.success("Розділ видалено");
    } catch (error) {
      message.error("Помилка при видаленні розділу");
    }
  }

  // PUT
  async activateDeactivate(establishmentID, id, activateDeactivateParams) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // PUT
  async reorder(establishmentID, updatedItems) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections`,
      );

      await update(createListRef, updatedItems);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка при оновлені");
    }
  }
}
