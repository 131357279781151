import React from "react";
import { Link } from "react-router-dom";
import { SIGN_UP_PATH } from "../auth/constants";

function Home() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>Стартова сторінка</h2>
      <Link to={`${SIGN_UP_PATH}`}>Реєстрація</Link>
    </div>
  );
}

export default Home;
