import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { database } from "../../configs";
import { getUserId } from "../../helpers";

export default class Products {
  // POST
  async create(establishmentID, data) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        ...data,
      });

      message.success("Товар створено");
    } catch (error) {
      message.error("Помилка при створенні товару");
    }
  }

  // PUT
  async update(establishmentID, ProductID, fieldParams) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await update(updateListRef, fieldParams);

      message.success("Товар оновлено");
    } catch (error) {
      message.error("Помилка при оновлені товару");
    }
  }

  // DELETE
  async delete(establishmentID, ProductID, hasSingleDelete = true) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await remove(deleteListRef);

      if (hasSingleDelete) {
        message.success("Товар видалено");
      }
    } catch (error) {
      if (hasSingleDelete) {
        message.error("Помилка при видаленні товару");
      }
    }
  }

  // PUT
  async activateDeactivate(
    establishmentID,
    ProductID,
    activateDeactivateParams,
  ) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка");
    }
  }

  // PUT
  async reorder(establishmentID, updatedItems) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products`,
      );

      await update(createListRef, updatedItems);

      message.success("Оновлено");
    } catch (error) {
      message.error("Помилка при оновлені");
    }
  }
}
