import React, { useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import ProductPreview from "./ProductPreview";
import ProductContent from "./ProductContent";
import { useMediaQuery } from "react-responsive";

function MenuProduct({
  isLast,
  selectedProduct,
  isPreview,
  className,
  ...props
}) {
  // Variables
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // State
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Functions
  function handleOpen() {
    if (isPreview) return;

    if (isTabletOrLarger) {
      setOpen(true);
    } else if (isMobile) {
      toggleDrawer(true);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  function toggleDrawer(open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  }

  return (
    <>
      <ProductContent
        handleOpen={handleOpen}
        isLast={isLast}
        selectedProduct={selectedProduct}
        isPreview={isPreview}
        className={className}
        {...props}
      />
      <ProductPreview
        open={open}
        handleClose={handleClose}
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        {...props}
      />
    </>
  );
}

MenuProduct.propTypes = {
  isLast: PropTypes.bool,
  selectedProduct: PropTypes.object,
  isPreview: PropTypes.bool,
  className: PropTypes.string,
};

export default observer(MenuProduct);
