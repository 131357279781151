import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import ScrollSpyTabs from "./ScrollSpyTabs";
import { observer } from "mobx-react";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import { Box, Container, Skeleton } from "@mui/material";
import PropTypes from "prop-types";

const MAX_PRODUCTS_FOR_CATEGORIES_DISPLAY = 50;

function MenuCategories({ sectionID, isFirstLoad, setIsFirstLoad }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [availableCategories, setAvailableCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Variables
  const { data } = mainStore.menu;

  const filteredCategories = Object.values(data?.Categories ?? {})
    .filter(
      (category) =>
        category.section_id === sectionID && category.category_active,
    )
    .sort((a, b) => a.position - b.position);

  const categoryIDs = Object.values(data?.Categories ?? {})
    .filter((category) => !category.category_active)
    .map((category) => category.id);
  const filteredProducts = Object.values(data?.Products ?? {}).filter(
    (product) =>
      product.product_active &&
      product.section_id === sectionID &&
      !categoryIDs.includes(product.category_id),
  );

  const categories =
    filteredProducts.length > MAX_PRODUCTS_FOR_CATEGORIES_DISPLAY &&
    !isFirstLoad
      ? availableCategories
      : filteredCategories;
  const scrollableCategoryTabs = categories.map((category) => ({
    id: category.id,
    name: category.category_name,
    description: category.category_description,
  }));

  // Effects
  useEffect(() => {
    const timeout = setTimeout(() => {
      setAvailableCategories(filteredCategories);
      setLoading(false);
      setIsFirstLoad(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [sectionID, filteredCategories, setIsFirstLoad]);

  if (
    filteredProducts.length > MAX_PRODUCTS_FOR_CATEGORIES_DISPLAY &&
    loading &&
    !isFirstLoad
  ) {
    return (
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Skeleton animation="wave" height={48} />
        <Skeleton variant="text" sx={{ fontSize: "1.5rem" }} />
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <Box
              key={index}
              className="tw-flex tw-items-center tw-gap-x-4 tw-mb-4"
            >
              <Box className="tw-flex-auto">
                <Skeleton variant="text" sx={{ fontSize: "1.25rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              </Box>
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={96}
                height={96}
                className="tw-rounded"
              />
            </Box>
          ))}
      </Container>
    );
  }

  if (!filteredCategories.length) {
    return (
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box component="h2" className="tw-text-center">
          Категорій не створено
        </Box>
      </Container>
    );
  }

  return <ScrollSpyTabs scrollableTabsData={scrollableCategoryTabs} />;
}

MenuCategories.propTypes = {
  sectionID: PropTypes.string.isRequired,
  isFirstLoad: PropTypes.bool.isRequired,
  setIsFirstLoad: PropTypes.func.isRequired,
};

export default observer(MenuCategories);
