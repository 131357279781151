import {
  FileDoneOutlined,
  SettingOutlined,
  LogoutOutlined,
  LayoutOutlined,
  ProfileOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import {
  ADMIN_PANEL_PREFIX_PATH,
  ADMIN_PANEL_SETTINGS_PATH,
} from "../constants";
import { logOut } from "../helpers";

const informationNavTree = [
  {
    key: "information",
    title: "sidenav.information",
    breadcrumb: false,
    submenu: [
      {
        key: "information-establishments",
        path: `${ADMIN_PANEL_PREFIX_PATH}/establishments`,
        title: "sidenav.information.establishments",
        icon: LayoutOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "information-menu",
        path: `${ADMIN_PANEL_PREFIX_PATH}/menu`,
        title: "sidenav.information.menu",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];
// TODO Uncomment this when it is necessary to add the Statistics or Reviews pages
// const statisticsNavTree = [
//   {
//     key: "statistics",
//     title: "sidenav.statistics",
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "statistics-statistics",
//         path: `${ADMIN_PANEL_PREFIX_PATH}/statistics`,
//         title: "sidenav.statistics.statistics",
//         icon: LineChartOutlined,
//         breadcrumb: false,
//         submenu: [],
//       },
//     ],
//   },
// ];
//
// const reviewsNavTree = [
//   {
//     key: "reviews",
//     title: "sidenav.reviews",
//     breadcrumb: false,
//     submenu: [
//       {
//         key: "reviews-reviews",
//         path: `${ADMIN_PANEL_PREFIX_PATH}/reviews`,
//         title: "sidenav.reviews.reviews",
//         icon: CommentOutlined,
//         breadcrumb: false,
//         submenu: [],
//       },
//     ],
//   },
// ];

const financeNavTree = [
  {
    key: "finance",
    title: "sidenav.finance",
    breadcrumb: false,
    submenu: [
      {
        key: "finance-tariffs",
        path: `${ADMIN_PANEL_PREFIX_PATH}/finance-tariffs`,
        title: "sidenav.finance.tariffs",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "finance-settings",
        path: `${ADMIN_PANEL_PREFIX_PATH}/finance-settings`,
        title: "sidenav.finance.settings",
        icon: CreditCardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const settingsNavTree = [
  {
    key: "settings",
    title: "sidenav.settings",
    breadcrumb: false,
    submenu: [
      {
        key: "settings-settings",
        path: `${ADMIN_PANEL_SETTINGS_PATH}`,
        title: "sidenav.settings.settings",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-logout",
        title: "sidenav.settings.logout",
        icon: LogoutOutlined,
        breadcrumb: false,
        onClick: (history) => logOut(history),
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...informationNavTree,
  ...financeNavTree,
  ...settingsNavTree,
];

export default navigationConfig;
