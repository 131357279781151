import React, { useContext } from "react";
import { Box, Container, Typography } from "@mui/material";
import { StoreContext } from "../../../../contexts/Store";
import { observer } from "mobx-react";
import { MenuSideMenu } from "./././menu-side-menu";
import { MenuThemeSwitcher } from "./menu-theme-switcher";
import { MenuSearch } from "../menu-main-content/menu-search";
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";
import { CONTAINER_MAX_WIDTH } from "../../constants";
import { MenuDivider } from "../../shared-components";

function MenuHeader() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { data, isThemeDark } = mainStore.menu;
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Box
      className={classnames({
        "tw-bg-neutral-50": !isThemeDark,
        "tw-bg-neutral-950": isThemeDark,
      })}
      component="header"
    >
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box className="tw-flex tw-items-center tw-justify-between tw-gap-x-2.5 tw-py-2.5">
          <Box className="tw-flex tw-items-center tw-gap-x-2.5 tw-max-w-full tw-overflow-hidden">
            <MenuSideMenu />
            <Typography
              component="h1"
              className="!tw-text-2xl !tw-font-bold tw-truncate"
            >
              {data.establishment_name}
            </Typography>
          </Box>
          <Box className="tw-flex tw-items-center tw-justify-end tw-gap-x-2.5">
            {isTabletOrLarger && (
              <MenuSearch isTabletOrLarger={isTabletOrLarger} />
            )}
            {/*// TODO Uncomment this when it is necessary to add the MenuReviews or MenuLanguage components*/}
            {/*<MenuReviews />*/}
            {/*<MenuLanguage />*/}
            <MenuThemeSwitcher />
          </Box>
        </Box>
      </Container>
      <MenuDivider />
    </Box>
  );
}

export default observer(MenuHeader);
