import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ADMIN_PANEL_ESTABLISHMENT_PATH,
  ADMIN_PANEL_MENU_PATH,
  ADMIN_PANEL_PREFIX_PATH,
  ADMIN_PANEL_SETTINGS_PATH,
} from "../../constants";
import { Loading } from "../../components/shared-components";

export const AdminPanelNavigation = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${ADMIN_PANEL_SETTINGS_PATH}`}
          component={lazy(() => import(`./settings/Settings.js`))}
        />
        <Route
          path={`${ADMIN_PANEL_ESTABLISHMENT_PATH}`}
          component={lazy(() => import(`./establishments/Establishments.js`))}
        />
        <Route
          path={`${ADMIN_PANEL_MENU_PATH}`}
          component={lazy(() => import(`./menu-settings/MenuNavigation.js`))}
        />
        <Redirect
          from={`${ADMIN_PANEL_PREFIX_PATH}`}
          to={`${ADMIN_PANEL_ESTABLISHMENT_PATH}`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AdminPanelNavigation);
