import { ADMIN_PANEL, ADMIN_PANEL_PREFIX_PATH, MENU } from "../constants";
import { MENU_PREFIX_PATH } from "../features/menu/constants";

const MODULE_URLS = {
  [ADMIN_PANEL]: ADMIN_PANEL_PREFIX_PATH,
  [MENU]: MENU_PREFIX_PATH,
};

export function getFeatureFromRoute(location) {
  const featureName = Object.keys(MODULE_URLS).find((module) =>
    location.pathname.startsWith(MODULE_URLS[module]),
  );

  return featureName || null;
}
