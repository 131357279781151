import React, { useContext } from "react";
import PropTypes from "prop-types";
import { BoxWrapper } from "../../../../shared-components";
import { StoreContext } from "../../../../../../contexts/Store";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function ProductSelection({ id, price }) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { mainColor, selectedProducts, selectedPortions } = mainStore.menu;
  const existingProductIndex = selectedProducts.findIndex(
    (product) => product.id === id,
  );
  const isProductExists = existingProductIndex !== -1;
  const product = selectedProducts.find((product) => product.id === id);
  const selectedPortion = selectedPortions.find(
    (portion) => portion.productId === id,
  );
  const portionPosition = selectedPortion ? selectedPortion.portionPosition : 0;

  // Functions
  function handleAddProduct(event) {
    event.stopPropagation();

    const updatedProducts = selectedProducts.map((product, index) => {
      if (index === existingProductIndex) {
        const updatedPortions = product.portions ? { ...product.portions } : {};

        const currentPortion = updatedPortions[portionPosition] || {
          count: 0,
          modifiedPrice: 0,
        };
        updatedPortions[portionPosition] = {
          count: currentPortion.count + 1,
          modifiedPrice: (currentPortion.count + 1) * price,
        };

        return {
          ...product,
          portions: updatedPortions,
        };
      }
      return product;
    });

    const newProduct = {
      id,
      portions: {
        [portionPosition]: {
          count: 1,
          modifiedPrice: price,
        },
      },
    };

    const finalProducts = isProductExists
      ? updatedProducts
      : [...updatedProducts, newProduct];

    mainStore.menu.setSelectedProducts(finalProducts);
  }

  function handleRemoveProduct(event) {
    event.stopPropagation();

    const updatedProducts = selectedProducts
      .map((product, index) => {
        if (index !== existingProductIndex) return product;

        const updatedPortions = { ...product.portions };
        const currentPortion = updatedPortions[portionPosition];

        if (!currentPortion) return product;

        const updatedCount = currentPortion.count - 1;

        if (updatedCount <= 0) {
          delete updatedPortions[portionPosition];
        } else {
          updatedPortions[portionPosition] = {
            count: updatedCount,
            modifiedPrice: currentPortion.modifiedPrice - price,
          };
        }

        const hasPortions = Object.keys(updatedPortions).length > 0;

        if (!hasPortions) {
          return null;
        } else {
          return {
            ...product,
            portions: updatedPortions,
          };
        }
      })
      .filter((product) => product !== null);

    mainStore.menu.setSelectedProducts(updatedProducts);
  }

  return (
    <Box className="tw-flex tw-items-center tw-gap-x-1.5 tw-pt-2.5">
      {isProductExists && !!product?.portions[portionPosition] ? (
        <>
          <BoxWrapper
            onClick={handleRemoveProduct}
            className="tw-font-bold !tw-min-w-10 !tw-h-10"
          >
            <RemoveIcon />
          </BoxWrapper>
          <Typography
            className="!tw-text-lg !tw-font-bold tw-uppercase tw-min-w-3 tw-text-center"
            style={{ color: mainColor }}
            onClick={(event) => event.stopPropagation()}
          >
            {product.portions[portionPosition].count}
          </Typography>
          <BoxWrapper
            onClick={handleAddProduct}
            className="tw-font-bold !tw-min-w-10 !tw-h-10"
          >
            <AddIcon />
          </BoxWrapper>
        </>
      ) : (
        <BoxWrapper
          onClick={handleAddProduct}
          className="tw-font-bold tw-uppercase tw-text-lg !tw-h-10"
        >
          Хочу
        </BoxWrapper>
      )}
    </Box>
  );
}

ProductSelection.propTypes = {
  id: PropTypes.string.isRequired,
  price: PropTypes.number,
};

export default observer(ProductSelection);
