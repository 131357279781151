import { Box, Container, Typography } from "@mui/material";
import TruncateMarkup from "react-truncate-markup";
import classnames from "classnames";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import {
  levelSpicyOptions,
  spicyLevelMap,
} from "../../../../../admin-panel/constants/productOptions";
import { GiChiliPepper } from "react-icons/gi";
import { LuVegan } from "react-icons/lu";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import ProductSelection from "./ProductSelection";
import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../../../contexts/Store";
import { observer } from "mobx-react";
import { CONTAINER_MAX_WIDTH } from "../../../../constants";
import { useMediaQuery } from "react-responsive";
import { MenuDivider } from "../../../../shared-components";
import PropTypes from "prop-types";
import ProductPortions from "./ProductPortions";

function ProductContent({
  handleOpen,
  isLast,
  selectedProduct,
  isPreview,
  className,
  ...props
}) {
  // Props
  const {
    id,
    name,
    description,
    url,
    portions,
    label_new: labelNew,
    level_spicy: levelSpicy,
    label_vegan: labelVegan,
  } = props;

  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { isThemeDark, selectedPortions } = mainStore.menu;
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isXsViewport = useMediaQuery({ query: "(max-width: 599px)" });
  const highlightedPeppers = spicyLevelMap[levelSpicy] || 0;

  const filteredPortions = portions?.filter((portion) => !!portion?.price);
  const validatedPortions = !!filteredPortions?.length
    ? filteredPortions
    : portions?.slice(0, 1) || [];
  const selectedPortion = selectedPortions.find(
    (portion) => portion.productId === id,
  );
  const portionPosition = selectedPortion ? selectedPortion.portionPosition : 0;
  const {
    price,
    weight_or_volume: weightOrVolume,
    cook_time: cookTime,
  } = validatedPortions.find((_, index) => index === portionPosition) || {};

  // State
  const [shouldTruncate, setShouldTruncate] = useState(true);

  // Functions
  function toggleTruncate(event) {
    event.stopPropagation();
    setShouldTruncate((prevShouldTruncate) => !prevShouldTruncate);
  }

  return (
    <>
      <Container maxWidth={CONTAINER_MAX_WIDTH}>
        <Box
          className={classnames(
            "tw-flex tw-justify-between tw-gap-x-4 tw-py-4",
            {
              "tw-border-solid": isTabletOrLarger && !isPreview,
              "tw-border-t-0": isTabletOrLarger && !isPreview,
              "tw-border-neutral-300":
                !isThemeDark && isTabletOrLarger && !isPreview,
              "tw-border-neutral-600":
                isThemeDark && isTabletOrLarger && !isPreview,
              "tw-border": isTabletOrLarger && !isPreview,
              "tw-rounded-b-md": isLast && !isPreview,
              "tw-px-4": isTabletOrLarger && !isPreview,
              "tw-pt-0": isMobile && isPreview && !!url,
              [className]: className,
            },
          )}
          onClick={handleOpen}
        >
          <Box className="tw-flex tw-flex-col">
            <Typography
              component="h3"
              className={classnames(
                "!tw-text-xl !tw-font-extrabold tw-uppercase",
                {
                  "tw-pt-2.5": isPreview && !!url,
                  "tw-pr-12": isPreview && !url,
                },
              )}
              sx={{
                wordBreak: "break-word",
              }}
            >
              {name}
            </Typography>
            <ProductPortions
              id={id}
              portions={portions}
              selectedProduct={selectedProduct}
            />
            {description && !isPreview && (
              <>
                {shouldTruncate ? (
                  <TruncateMarkup
                    lines={2}
                    ellipsis={
                      <Typography component="span" onClick={toggleTruncate}>
                        ...
                        <Typography
                          className="!tw-font-semibold"
                          component="span"
                        >
                          {" "}
                          показати
                        </Typography>
                      </Typography>
                    }
                  >
                    <Typography component="p" className="!text-sm">
                      {description}
                    </Typography>
                  </TruncateMarkup>
                ) : (
                  <Typography component="p" className="!text-sm">
                    {description}
                    <Typography
                      component="span"
                      className="!tw-font-semibold tw-whitespace-nowrap"
                      onClick={toggleTruncate}
                    >
                      {" "}
                      сховати
                    </Typography>
                  </Typography>
                )}
              </>
            )}
            {description && isPreview && (
              <Typography component="p" className="!text-sm">
                {description}
              </Typography>
            )}
            {(weightOrVolume ||
              cookTime ||
              labelNew ||
              levelSpicy ||
              labelVegan) && (
              <Box
                className={classnames(
                  "tw-flex",
                  "tw-items-center",
                  "tw-flex-wrap",
                  "tw-gap-x-1.5",
                  "tw-gap-y-1",
                  "tw-text-sm",
                  "tw-font-semibold",
                  {
                    "tw-pt-1": !!description,
                  },
                )}
              >
                {weightOrVolume && (
                  <>
                    <ScaleOutlinedIcon className="!tw-text-base" />{" "}
                    {weightOrVolume}
                  </>
                )}
                {cookTime && (
                  <>
                    <TimerOutlinedIcon className="!tw-text-base" /> {cookTime}
                  </>
                )}
                {levelSpicy && (
                  <Box className="tw-flex tw-items-center tw-text-base">
                    {levelSpicyOptions.map((option, index) => {
                      return (
                        <GiChiliPepper
                          key={option.value}
                          className={classnames({
                            "tw-text-neutral-500": isThemeDark,
                            "!tw-text-red-default": index < highlightedPeppers,
                          })}
                        />
                      );
                    })}
                  </Box>
                )}
                {labelVegan && (
                  <Box className="tw-flex tw-items-center tw-text-base">
                    <LuVegan className="tw-text-green-default" />
                  </Box>
                )}
                {labelNew && (
                  <Box className="tw-flex tw-items-center tw-h-4">
                    <FiberNewOutlinedIcon
                      fontSize="large"
                      className="tw-text-red-default"
                    />
                  </Box>
                )}
              </Box>
            )}
            <ProductSelection id={id} price={price} />
          </Box>
          {url && (
            <Box
              className={classnames("tw-relative", {
                "tw-min-w-24 tw-min-h-24 tw-w-24 tw-h-24": !isPreview,
                " tw-max-w-md tw-max-h-md tw-aspect-square": isPreview,
                "tw-w-[calc(100% + 48px)] tw--mx-6": !isXsViewport && isPreview,
                "tw-w-[calc(100% + 32px)] tw--mx-4": isXsViewport && isPreview,
              })}
            >
              <Box
                className={classnames("!tw-absolute tw-z-[-1] tw-inset-0", {
                  "tw-w-24 tw-h-24 tw-min-w-24 tw-min-h-24": !isPreview,
                  "tw-w-full tw-h-full tw-z-0": isPreview,
                })}
              >
                <Box
                  component="span"
                  className={classnames("tw-w-full tw-h-full tw-flex", {
                    "tw-bg-neutral-300": !isThemeDark,
                    "tw-bg-neutral-700": isThemeDark,
                    "tw-rounded": !isPreview,
                    "tw-rounded-t-lg": isPreview,
                  })}
                />
              </Box>
              <Box
                component="img"
                className={classnames({
                  "tw-w-24 tw-h-24 tw-min-w-24 tw-min-h-24 tw-rounded":
                    !isPreview,
                  "tw-flex tw-relative tw-z-10 tw-w-full tw-h-full tw-rounded-t-lg":
                    isPreview,
                })}
                src={url}
                alt={name}
              />
            </Box>
          )}
        </Box>
      </Container>
      {isMobile && !isLast && !isPreview && <MenuDivider />}
    </>
  );
}

ProductContent.propTypes = {
  handleOpen: PropTypes.func,
  isLast: PropTypes.bool,
  selectedProduct: PropTypes.object,
  isPreview: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  labelNew: PropTypes.bool,
  levelSpicy: PropTypes.string,
  labelVegan: PropTypes.bool,
  portions: PropTypes.arrayOf(
    PropTypes.shape({
      portion_size: PropTypes.string,
      price: PropTypes.number,
      units_for_price: PropTypes.string,
      weight_or_volume: PropTypes.string,
      cook_time: PropTypes.string,
    }),
  ),
};

export default observer(ProductContent);
