import antdUkUa from "antd/es/locale/uk_UA";
import ukMsg from "../locales/uk_UA.json";

const UkLang = {
  antd: antdUkUa,
  locale: "uk-UA",
  messages: {
    ...ukMsg,
  },
};
export default UkLang;
