export const levelSpicyOptions = [
  {
    label: "Незначний",
    value: "mild",
  },
  {
    label: "Середній",
    value: "medium",
  },
  {
    label: "Гострий",
    value: "hot",
  },
];

export const spicyLevelMap = {
  mild: 1,
  medium: 2,
  hot: 3,
};

export const allergensOptions = [
  {
    label: "Яйця",
    value: "Яйця",
  },
  {
    label: "Молоко та продукти на його основі",
    value: "Молоко та продукти на його основі",
  },
  {
    label: "Риба",
    value: "Риба",
  },
  {
    label: "Ракоподібні / молюски",
    value: "Ракоподібні / молюски",
  },
  {
    label: "Горіхи",
    value: "Горіхи",
  },
  {
    label: "Арахіс",
    value: "Арахіс",
  },
  {
    label: "Злаки (які містять глютен)",
    value: "Злаки (які містять глютен)",
  },
  {
    label: "Соя",
    value: "Соя",
  },
  {
    label: "Інше",
    value: "Інше",
  },
];
