import React, { useContext } from "react";
import { observer } from "mobx-react";
import { MenuSections } from "./menu-sections";
import { MenuSearch, MenuSearchContent } from "./menu-search";
import { StoreContext } from "../../../../contexts/Store";
import { useMediaQuery } from "react-responsive";
import { Container } from "@mui/material";
import { MenuSelectedProducts } from "./menu-selected-products";
import { MenuScrollToTop } from "./menu-scroll-to-top";
import { CONTAINER_MAX_WIDTH } from "../../constants";

function MenuMainContent() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Variables
  const { search, selectedProducts } = mainStore.menu;
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      {isMobile && (
        <Container maxWidth={CONTAINER_MAX_WIDTH} className="tw-pt-3">
          <MenuSearch />
        </Container>
      )}
      {!!search ? <MenuSearchContent /> : <MenuSections />}
      {!!selectedProducts.length && <MenuSelectedProducts />}
      <MenuScrollToTop />
    </>
  );
}

export default observer(MenuMainContent);
